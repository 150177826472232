import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Layout from "../components/layoutMainCustom"
import BgImage from "../images/myanmarBig.jpg"
import BaptismPic from "../images/MyanmarBaptism.jpg"
import MeetThePastorMyanmar from "../components/meetthePastorMyanmar"
import SEO from "../components/seo"
import PageHeaders from "../components/pageHeaders"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Myanmar Campus"
    SecondText="Making Jesus Known around the world."
  >
    <SEO title="Myanmar Campus" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Cross Church Myanmar
    </PageHeaders>
    <HeaderText></HeaderText>
    <div
      css={css`
        display: flex;
        @media (max-width: 940px) {
          flex-direction: column;
        }
      `}
    >
      <div>
        <ParagraphText>
          Our Cross Church Myanmar Campus is led by a former Buddhist by the
          name of Pastor Jonah. They are doing an amazing job making Jesus known
          in their village and seeing new believers following Jesus in baptism.{" "}
        </ParagraphText>
        <HeaderText></HeaderText>
        <ParagraphText>
          Currently, we are in the process of building their first structure for
          worship after purchasing a plot of land this past year. Your{" "}
          <a href="https://pushpay.com/pay/palmvistasurprise/3wVi2Zku2LnNwZiQ57OSwA">
            giving
          </a>{" "}
          through Cross Church helps us to literally take the Gospel around the
          world.
        </ParagraphText>
      </div>
      <img
        src={BaptismPic}
        alt="baptism in Myanmar"
        css={css`
          width: 360px;
          margin-left: 4rem;
          border-radius: 6px;
          @media (max-width: 940px) {
            width: 100%;
            margin-top: 2rem;
            margin-left: 0;
          }
        `}
      />
    </div>
    <HeaderText></HeaderText>
    <MeetThePastorMyanmar />
  </Layout>
)

export default IndexPage
