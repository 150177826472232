import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import PageHeaders from "./pageHeaders"
import JonahPic from "../images/Jonah_profile.png"
import ModalVideo from "react-modal-video"

const UnderlineText = styled(Link)`
  font-family: Montserrat;
  font-weight: 400;
  text-transform: uppercase;
  font-style: italic;
  font-size: 0.8rem;
  color: #312e2e;
  text-decoration: none;
  border-bottom: 1px solid #312e2e;
  @media (max-width: 940px) {
    text-align: center;
    margin-top: 1rem;
    font-size: 1.25rem;
    border-bottom: none;
    text-decoration: underline;
  }
`

const MainText = styled.h2`
  font-family: Montserrat;
  font-weight: 900;
  color: #312e2e;
  font-size: 1.8rem;
  margin-bottom: 0.25rem;
  line-height: 1;
  @media (max-width: 940px) {
    text-align: center;
    margin-top: 1rem;
  }
`

const MainButton = styled.button`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: 940px) {
    margin: 1rem 0;
    width: 100%;
  }
`

const SecondaryButton = styled(Link)`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  margin-left: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  @media (max-width: 940px) {
    margin-left: 0;
    width: 95%;
    justify-content: center;
  }
`

class MeetThePastorMyanmar extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <>
        <PageHeaders>Meet the Pastor</PageHeaders>
        <div
          css={css`
            background-color: #edf2f7;
            margin-bottom: 4rem;
            display: flex;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            border-radius: 17px;
            flex-direction: row;
            align-items: center;
            height: 250px;
            margin-top: 4rem;
            @media (max-width: 940px) {
              flex-direction: column;
              height: 300px;
              justify-items: center;
            }
          `}
        >
          <img
            css={css`
              width: 180px;
              margin-left: 4rem;
              @media (max-width: 940px) {
                margin-left: 0;
                margin-top: 2rem;
              }
            `}
            src={JonahPic}
            alt="picture of Pastor Jonah"
          />
          <div
            css={css`
              margin-left: 6rem;
              @media (max-width: 940px) {
                margin-left: 0;
                display: flex;
                justify-items: center;
                flex-direction: column;
              }
            `}
          >
            <MainText>
              Pastor Jonah
              <br />
              <i
                css={css`
                  font-size: 1.5rem;
                  font-weight: 300;
                `}
              >
                Campus Pastor of Myanmar
              </i>
            </MainText>
          </div>
        </div>
      </>
    )
  }
}

export default MeetThePastorMyanmar
